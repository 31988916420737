import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/whereSC.jpg"

function Hvac() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap hvacWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap hvacOverview">
          <div className="textWrap">
            <h2>HVAC 공조시스템</h2>
            <p className="bodyText">
              모바일과 무선 네트워크 기술은 중소규모 건물에서 24시간 모니터링을
              가능하게 하고 에너지 관리 비용을 최소화할 수 있습니다. 필요한
              지점에 설치된 센서는 자동으로 온도를 측정해 사람을 확인하고
              네트워크를 통해 에어컨과 에어컨 시스템을 자동으로 제어합니다.
              <br />
              <br />
              축적된 데이터베이스를 바탕으로 실제 에어컨 시스템 사용량을
              계산하기 위해 사용 공간과 접속 인원을 계산하고, 자동제어를 활용해
              최적의 공간을 만드는 ELSA 솔루션은, 건물과 사무실은 공실, 공실
              등으로 판단돼 에어컨이 자동으로 켜고 끄며 교실과 기숙사는 강의
              일정을 자동으로 정하고 방과 후 자동으로 냉난방 시스템을 가동할 수
              있습니다. 또 각 건물은 네트워크에 접속해 감시와 중앙집중식 제어를
              할 수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Hvac
